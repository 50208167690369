import React, { useState, useEffect, useReducer } from "react";
import "../containers/AddMultiSlideLayout.css";
import PropTypes from "prop-types";
import { Loading } from "../components/Loading";
import { Helmet } from "react-helmet";
import { head, getMeta } from "../utils/head";
import { Feedback, FormLabel } from "../components/FormControls";
import { ErrorSummary } from "../components/ErrorSummary";
import { Form, Row, Col, Button, Table } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { ResumableUploader } from "../components/ResumableUploader";
import { getWsUrl, wsCall } from "../utils/wsUtils";
import ReactTable from "react-table";
import "../containers/AddMultiSlideLayout.css";
import { getMetadataPath } from "../utils/commonUtils";
import Container from "@material-ui/core/Container";
import { Card } from "react-bootstrap";
import { PageHeading } from "../components/FormControls";
import { BlueCheckbox } from "../components/FormControls";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { HelpToolTip } from "../components/tooltip/HelpToolTip";
import { Typography } from "@material-ui/core";
import wikiHelpTooltip from "../appData/wikiHelpTooltip";
import FeedbackWidget from "../components/FeedbackWidget";

const MAX_ERROR_LIMIT = 10;

const AddMetadataFromFile = props => {
    const fileMap = new Map();

    useEffect(() => {
        props.authCheckAgent();

        if (fileMap.size === 0) {
            fileMap.set(".json", "importmetadata");
        }

        setFileTypeApiValues(fileMap);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showLoading, setShowLoading] = useState(false);
    const [showErrorSummary, setShowErrorSummary] = useState(false);
    const [pageErrorsJson, setPageErrorsJson] = useState({});
    const [pageErrorMessage, setPageErrorMessage] = useState("");
    const [tableData, setTableData] = useState([]);
    const [rowSelected, setRowSelected] = useState([]);
    const [tabs, setTabs] = useState({});
    const history = useHistory();
    var columnsToRender = Object.assign({});

    const [fileSubmitted, setFileSubmitted] = useState(false);
    const [fileTypeApiValues, setFileTypeApiValues] = useState(new Map());

    const [uploadedFile, setUploadedFile] = useState();

    const defaultFileType = "*/*";

    var base = process.env.REACT_APP_BASENAME;

    const fileDetails = {
        fileType: defaultFileType,
        name: "",
        description: ""
    };

    const [uploadDetails, setUploadDetails] = useReducer((state, newState) => ({ ...state, ...newState }), fileDetails);

    function handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        setUploadDetails({ [name]: value });
    }

    const checkSelection = row => {
        return rowSelected.find(e => e.id === row.id);
    };

    const handleChecboxChange = row => {
        let selectedRows = [...rowSelected];
        let deselectedRow = selectedRows.find(e => e.id === row.id);

        if (deselectedRow) {
            var deselectedRowIndex = selectedRows.indexOf(deselectedRow);
            selectedRows.splice(deselectedRowIndex, 1);
        } else {
            selectedRows.push({
                id: row.id,
                name: row.name,
                type: props.templateType
            });
        }

        setRowSelected(selectedRows);
    };

    const getUpdateName = row => {
        if (rowSelected) {
            const selectedRow = rowSelected.find(e => e.id === row.id);
            return selectedRow && selectedRow.name !== "" ? selectedRow.name : row.name;
        }
    };

    const handleNameChange = (row, e) => {
        const value = e.target.value;
        const updateSelectedRows = [...rowSelected];

        if (updateSelectedRows && updateSelectedRows.length > 0 && updateSelectedRows.find(e => e.id === row.id)) {
            updateSelectedRows.forEach(element => {
                if (element.id === row.id) {
                    element.name = value;
                }
            });
        } else {
            updateSelectedRows.push({
                id: row.id,
                name: value,
                type: props.templateType
            });
        }

        setRowSelected(updateSelectedRows);
    };

    const getMessages = (key, value) => {
        const trows = row => {
            var trow = [];
            trow = row.map((element, index) => {
                return (
                    <tr key={index}>
                        <td style={{ width: "50px", marginLeft: "100px" }}>{element.name}</td>
                        <td style={{ width: "50px", marginRight: "100px" }}>{element.description}</td>
                    </tr>
                );
            });
            return trow;
        };

        const trowsForErrors = errors => {
            const trow = [];

            errors.forEach((element, index) => {
                var description = "";

                element.error.errors.forEach(error => {
                    description += error.objectName + " - " + error.defaultMessage + "\n";
                });

                trow.push(
                    <tr key={index}>
                        <td style={{ width: "50px", marginLeft: "100px" }}>{element.layout.name}</td>
                        <td
                            style={{
                                width: "50px",
                                marginRight: "100px",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            {description}
                        </td>
                    </tr>
                );
            });
            return trow;
        };

        const tbody = () => {
            const tbody = [];
            if (key !== "errors") {
                tbody.push(value && trows(value));
            } else {
                tbody.push(value && trowsForErrors(value));
            }
            return tbody;
        };

        return (
            <>
                <Table>
                    <thead>
                        <tr>
                            <th>Metadata Name</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>{tbody()}</tbody>
                </Table>

                {/* {key === "errors" && (
              <Col md={{ span: 3, offset: 4 }}>
                <Link to="/slideLayouts" className="link-button">
                  Back to Slidelayouts
                </Link>
              </Col>
            )} */}
            </>
        );
    };

    const getMetadataFromFile = tableData => {
        const editName = row => (
            <input
                key={row.index}
                type="text"
                name={row.original.name}
                onChange={e => handleNameChange(row.original, e)}
                value={getUpdateName(row.original)}
                style={{
                    border: "1px solid rgba(0, 0, 0, 0.1)"
                }}
            />
        );

        columnsToRender["nameColumn"] = {
            Header: "Name",
            accessor: "name",
            // eslint-disable-next-line react/display-name
            Cell: row => editName(row)
        };

        columnsToRender["selectionColumn"] = {
            Header: "Select",
            sortable: false,
            // eslint-disable-next-line react/display-name
            Cell: row => (
                <FormControlLabel
                    control={<BlueCheckbox />}
                    name="selectionColumn"
                    key={row.index}
                    onChange={() => handleChecboxChange(row.original)}
                    checked={checkSelection(row.original)}
                />
            )
        };

        return (
            <>
                {Object.keys(tabs).length === 0 &&
                    (!showErrorSummary) && (
                        <Form onSubmit={e => addSelectedMetadata(e)}>
                            <ReactTable
                                columns={Object.values(columnsToRender)}
                                data={tableData}
                                pageSizeOptions={[5, 10, 25]}
                                defaultPageSize={10}
                                minRows={0}
                                className="MyReactTableClass"
                                NoDataComponent={({ state, ...rest }) =>
                                    !state?.loading ? (
                                        <p className="pt-2 text-center">
                                            <strong>No data available</strong>
                                        </p>
                                    ) : null
                                }
                                keyColumn="name"
                                showPaginationTop
                                sortable={true}
                            />
                            <div className="text-center mb-2 mt-4">
                                <Link to={`/${getMetadataPath(props.templateType)}`}>
                                    <Button className="gg-btn-outline mt-2 gg-mr-20">Back to Metadata</Button>
                                </Link>
                                <Button type="submit" disabled={rowSelected.length < 1} className="gg-btn-blue mt-2 gg-ml-20">
                                    Add Selected Metadata
                                </Button>
                            </div>
                        </Form>
                    )}

                {Object.keys(tabs).length > 0 &&
                    Object.entries(tabs).map(element => {
                        var key = element[0];
                        var value = element[1];

                        if (value && Array.isArray(value) && value.length > 0) {
                            return (
                                <>
                                    <div
                                        style={{
                                            fontWeight: "bold",
                                            padding: "15px",
                                            marginBottom: "20px",
                                            backgroundColor:
                                                key === "addedMetadata" ? "darkseagreen" : key === "duplicates" ? "orange" : "indianred"
                                        }}
                                    >
                                        {key === "addedMetadata"
                                            ? " Metadata Added to the Repository"
                                            : key === "duplicates"
                                                ? " Metadata with Duplicate Name"
                                                : "Errors"}
                                    </div>
                                    <div
                                        style={{
                                            padding: "15px",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        {key === "duplicates"
                                            ? " You can go back to the selections and update the name if you still would like to be able to add the selected metadata"
                                            : ""}
                                    </div>

                                    {getMessages(key, value)}

                                    <div>
                                        <div className="text-center mb-2">
                                            <Link to={`/${getMetadataPath(props.templateType)}`}>
                                                <Button className="gg-btn-outline mt-2 gg-mr-20">Back to Metadata</Button>
                                            </Link>
                                            <Button onClick={() => resetSelection()} className="gg-btn-blue mt-2 gg-ml-20">
                                                Back to Select Metadata
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            );
                        }
                        return <></>;
                    })}
            </>
        );
    };

    const resetSelection = () => {
        setTabs({});
        setRowSelected([]);
    };

    const addSelectedMetadata = e => {
        setShowLoading(true);

        wsCall(
            fileTypeApiValues.get(uploadDetails.fileType),
            "POST",
            null,
            true,
            {
                file: {
                    identifier: uploadedFile.identifier,
                    originalName: uploadedFile.originalName,
                    fileFolder: uploadedFile.fileFolder,
                    fileFormat: uploadedFile.fileFormat
                },
                selectedMetadata: rowSelected
            },
            importMetadataSuccess,
            importMetadataError
        );

        e.preventDefault();
    };

    function importMetadataSuccess(response) {
        response.json().then(responseJson => {
            setTabs(responseJson);
            setShowErrorSummary(false);
            setShowLoading(false);
        });

        /*history.push({
            pathname: `/${getMetadataPath(props.templateType)}`,
            state: {
                templateType: props.templateType,
            },
        });*/
    }

    function importMetadataError(response) {
        response.json().then(responseJson => {
            if (responseJson.errors) {
                setPageErrorsJson(responseJson);
                setTabs(responseJson.errors);
                setShowErrorSummary(true);
            } else {
                if (responseJson.message)
                    setPageErrorsJson(responseJson.message);
                else
                    setPageErrorsJson(responseJson);
                //setTabs(responseJson.errors);
                setShowErrorSummary(true);
            }
            setShowLoading(false);
        });
    }

    const processFile = fileId => {
        wsCall(
            "getmetadatafromfile",
            "GET",
            {
                file: encodeURIComponent(fileId),
                template: props.templateType
            },
            true,
            null,
            getMetadatFromFileSuccess,
            getMetadatFromFileError
        );

        function getMetadatFromFileSuccess(response) {
            response.json().then(responseJson => {
                console.log(responseJson);
                setShowErrorSummary(false);
                setTableData(responseJson);
                setFileSubmitted(true);
            });
        }

        function getMetadatFromFileError(response) {
            response.json().then(responseJson => {
                console.log(responseJson);
                setFileSubmitted(false);
                setPageErrorsJson(responseJson);
                setPageErrorMessage("");
                setShowErrorSummary(true);
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>{head.addMetadataFromFile.title}</title>
                {getMeta(head.addMetadataFromFile)}
            </Helmet>
            <FeedbackWidget />
            <Container maxWidth="xl">
                <div className="page-container">
                    <PageHeading
                        title="Add Metadata From File to Repository"
                        subTitle={fileSubmitted ? "Please select the metadata to import. You can enter a new name for selected metadata" : "Please upload a file wih metadata."}
                    />
                    <Typography className="text-right" gutterBottom>
                        <HelpToolTip
                            title={wikiHelpTooltip.metadata.add_metadata_from_file.title}
                            text={wikiHelpTooltip.tooltip_text}
                            url={wikiHelpTooltip.metadata.add_metadata_from_file.url}
                        />
                        {wikiHelpTooltip.help_text}
                    </Typography>

                    <Card>
                        <Card.Body>

                            {showErrorSummary === true && (
                                <div>
                                    <h5>File upload failed for following reasons:</h5>

                                    <ErrorSummary
                                        show={showErrorSummary}
                                        form="importMetadata"
                                        errorJson={pageErrorsJson}
                                        errorMessage={pageErrorMessage}
                                    ></ErrorSummary>

                                    {fileSubmitted && (
                                        <div className="text-center mb-2">
                                            <Link to={`/${getMetadataPath(props.templateType)}`} className="link-button">
                                                <Button className="gg-btn-outline mt-2">Back to Metadata</Button>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            )}

                            {Object.keys(tabs).length === 0 && !fileSubmitted && (
                                <>
                                    <Form.Group as={Row} controlId="fileType" className="gg-align-center mb-3">
                                        <Col xs={12} lg={9}>
                                            <FormLabel label="Choose File Type for Upload" className="required-asterik" />
                                            <Form.Control
                                                as="select"
                                                name="fileType"
                                                placeholder="fileType"
                                                onChange={handleChange}
                                                required={true}
                                                value={uploadDetails.fileType}
                                            >
                                                <option value={defaultFileType}>Select File Type</option>
                                                <option value=".json">Repository Export (.json)</option>
                                            </Form.Control>
                                            <Feedback message="Please choose a file type for the file to be uploaded"></Feedback>
                                        </Col>
                                    </Form.Group>

                                    {uploadDetails.fileType === defaultFileType && (
                                        <div className="text-center mb-2 mt-4">
                                            <Link to={`/${getMetadataPath(props.templateType)}`}>
                                                <Button className="gg-btn-outline mt-2">Back to Metadata</Button>
                                            </Link>
                                        </div>
                                    )}

                                    {uploadDetails.fileType === ".json" && (
                                        <Form.Group as={Row} controlId="fileUploader" className="gg-align-center">
                                            <Col xs={12} lg={9}>
                                                <ResumableUploader
                                                    history={history}
                                                    headerObject={{
                                                        Authorization: window.localStorage.getItem(base ? base + "_token" : "token") || "",
                                                        Accept: "*/*",
                                                    }}
                                                    fileType={fileDetails.fileType}
                                                    uploadService={getWsUrl("upload")}
                                                    maxFiles={1}
                                                    onProcessFile={processFile}
                                                    enableSubmit
                                                    filetypes={["json"]}
                                                    onCancel={() => history.push(`/${getMetadataPath(props.templateType)}`)}
                                                    setUploadedFile={setUploadedFile}
                                                />
                                            </Col>
                                        </Form.Group>
                                    )}
                                </>
                            )}

                            {fileSubmitted && getMetadataFromFile(tableData)}

                            <Loading show={showLoading}></Loading>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </>
    );
}

AddMetadataFromFile.propTypes = {
    authCheckAgent: PropTypes.func,
    templateType: PropTypes.string
};

export default AddMetadataFromFile;